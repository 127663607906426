
<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'Inicio',

    metaInfo: { title: 'Inicio' },

    extends: View,

    mixins: [
      LoadSections([
        //'news', usado para popup
        'hero',
        //'newsletter',
        //'contact-us',
        'ConvocatoriaCertificacion',
        'ConvocatoriaRecertificacion',
        //'CitatorioAsamblea', //para cuando fue lo del congreso
        'mision',
       // 'about-our-product',
        'features',
       // 'theme-features',
        //'recent-works',
        'Reglamentos',


        //'news',
        //'sitios-interes',

        //'customer-reviews',
        //'affiliates',
        //'social-media',

        'info-alt',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'home',
      },
    },
  }
</script>
